
import { Options, Vue } from 'vue-class-component'
import { toDate } from '../utils/common'
import { Dialog, Toast } from 'vant'
@Options({
  components: {}
})
export default class Home extends Vue {
  toDate = toDate;

  search = {
    keyword: '',
    approval_group: '',
    product_no: '',
    status: '',
    sort: {
      created_at: 0
    },
    pagination: {
      page: 0,
      pageSize: 20
    }
  };

  config: any = {};
  refreshing = false;
  loading = false;
  finished = false;
  error = false;
  list: any = [];

  quxiao () {
    Dialog.confirm({
      title: '温馨提示',
      message: '您确定要取消该订单吗'
    })
      .then(() => {
        // on confirm
        Toast.success('取消成功')
      })
      .catch(() => {
        // on cancel
      })
  }

  go (name:string) {
    this.$router.push(name)
  }

  fapiao () {
    Dialog.alert({
      title: '温馨提示',
      message: '申请成功'
    })
  }

  downpiao () {
    Dialog.alert({
      title: '温馨提示',
      message: '下载发票成功'
    })
  }

  downbh () {
    Dialog.alert({
      title: '温馨提示',
      message: '下载保函成功'
    })
  }

  getOrderStatus (type: number) {
    if (type === 4 || type === 5) {
      return 'success'
    }
    if (type === 2) {
      return 'danger'
    }
    return 'warning'
  }

  onSearch () {
    this.refreshing = true
    this.onRefresh()
  }

  onRefresh () {
    // 清空列表数据
    this.finished = false
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true
    this.onLoad()
  }

  onLoad () {
    if (this.refreshing) {
      this.search.pagination.page = 0
      this.list = []
      this.refreshing = false
    }
    // api
    //   .APPROVAL_V3_APPLY_WORK(this.search)
    //   .then((data: any) => {
    const data: any = {
      total: 5,
      list: [
        {
          approval_group: 'group_order',
          title: '履约保函',
          name: '123123',
          status: 1
        },
        {
          approval_group: 'group_order',
          title: '履约保函',
          name: '123123',
          status: 2
        },
        {
          approval_group: 'group_order',
          title: '履约保函',
          name: '123123',
          status: 3
        },
        {
          approval_group: 'group_order',
          title: '履约保函',
          name: '123123',
          status: 4
        },
        {
          approval_group: 'group_order',
          title: '履约保函',
          name: '123123',
          status: 5
        }
      ]
    }
    this.search.pagination.page++
    this.list = [...this.list, ...data.list]
    if (this.list.length >= data.total) {
      this.finished = true
    }
    this.loading = false
    // })
    // .catch(() => {
    //   this.error = true
    // })
  }

  getConfig () {
    this.config = {
      status: {
        0: '全部',
        1: '审批中',
        2: '待支付',
        3: '待签约',
        4: '出函中',
        5: '已出函'
      }
    }
  }

  created () {
    this.getConfig()
  }
}
